import { Col, Container, Row } from "react-bootstrap"
import React, { FC } from "react"

import { Resource } from "@utils/globalTypes"
import * as s from "./SourcesContainer.module.scss"

type Props = {
  resourcePage: Resource
}
const SourcesContainer: FC<Props> = ({ resourcePage }) => {
  const { sources } = resourcePage
  return (
    <Container className={s.container}>
      <Row>
        <Col xs={12} className={s.titleContainer}>
          <h2 className={s.title}>Sources</h2>
        </Col>
        <Col xs={12} className={s.sourcesContainer}>
          <ul>
            {sources.map(({ href, caption }, index) => (
              <li key={index}>
                <a href={href} target="_blank">
                  {caption}
                </a>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default SourcesContainer
