import { AllGraphQLResponse, Product, Resource } from "@utils/globalTypes"
import React, { FC } from "react"

import Detail from "./Detail/Detail"
import { MOBILE_WIDTH } from "@utils/util"
import SEO from "@components/common/SEO"
import { graphql } from "gatsby"
import useResizeWidth from "@hooks/useResizeWidth"

type Props = {
  data: {
    sanityResourcePage: Resource
    allShopifyProduct: AllGraphQLResponse<Product>
  }
  location: Location
}

const ResourcePage: FC<Props> = ({
  data: { sanityResourcePage, allShopifyProduct },
  location,
}) => {
  const { meta } = sanityResourcePage as Resource
  const { edges } = allShopifyProduct
  const relatedProducts = edges.map((edge) => edge.node)
  const metadata = {
    title: meta?.title,
    description: meta?.description,
    location: location.href,
  }
  const { width } = useResizeWidth()
  const isMobile = typeof window !== "undefined" ? width <= MOBILE_WIDTH : true

  return (
    <>
      <SEO {...metadata} />
      {isMobile ? (
        <Detail.Mobile
          products={relatedProducts}
          resourcePage={sanityResourcePage}
        />
      ) : (
        <Detail.Desktop
          products={relatedProducts}
          resourcePage={sanityResourcePage}
        />
      )}
    </>
  )
}

export default ResourcePage

export const query = graphql`
  query sanityResourcePage($handle: String!, $productType: String) {
    sanityResourcePage(slug: { current: { eq: $handle } }) {
      title
      date
      _rawContent(resolveReferences: { maxDepth: 10 })
      sources {
        href
        caption
      }
      author {
        title
        category
        name
        order
        _rawBio
        position
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      medReview {
        title
        category
        name
        order
        _rawBio
        position
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      image {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      relatedProducts {
        title
      }
      relatedResources {
        title
        _rawContent(resolveReferences: { maxDepth: 10 })
        slug {
          current
        }
        category {
          name
          slug {
            current
          }
        }
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      meta {
        description
        title
      }
    }
    allShopifyProduct(filter: { productType: { eq: $productType } }) {
      edges {
        node {
          id
          description
          title
          handle
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`
