import React, { FC, useState } from "react"

import { Resource } from "@utils/globalTypes"
import { formatDate } from "@utils/util"
import * as s from "./AuthorsInfoContainer.module.scss"
import EmployeeDetail from "@components/employees/EmployeeDetail"

type Props = {
  resourcePage: Resource
}

const AuthorsInfoContainer: FC<Props> = ({ resourcePage }) => {
  const { author, date, medReview } = resourcePage
  const [show, setShow] = useState(false)
  const [showMed, setShowMed] = useState(false)

  const handleClose = () => {
    setShow(false)
  }

  const handleCloseMed = () => {
    setShowMed(false)
  }

  return (
    <div className={s.container}>
      {author && (
        <div className={s.writtenBy}>
          Written By{" "}
          <button onClick={() => setShow(true)} style={{ color: "#226355" }}>
            {author.name}
          </button>{" "}
          -
        </div>
      )}

      <div>
        {medReview && (
          <div className={s.writtenBy}>
            Medically Reviewed By{" "}
            <button
              onClick={() => setShowMed(true)}
              style={{ color: "#226355" }}
            >
              {medReview.name}
            </button>{" "}
            -
          </div>
        )}
        {date &&
          `Published on
          ${formatDate(date)}`}
      </div>
      {author && (
        <EmployeeDetail employee={author} show={show} onHide={handleClose} />
      )}
      {medReview && (
        <EmployeeDetail
          employee={medReview}
          show={showMed}
          onHide={handleCloseMed}
        />
      )}
    </div>
  )
}

export default AuthorsInfoContainer
