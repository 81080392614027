import { Col, Container, Row } from "react-bootstrap"
import { Product, Resource } from "@utils/globalTypes"
import React, { FC } from "react"
import AuthorsInfoContainer from "../AuthorsInfoContainer"
import ImageDescriptionContainer from "../ImageDescriptionContainer"
import RelatedProductsContainer from "../RelatedProductsContainer"
import RelatedResourcesContainer from "../RelatedResourcesContainer/RelatedResourcesContainer"
import SourcesContainer from "../SourcesContainer"
import * as s from "./Detail.module.scss"

type Props = {
  resourcePage: Resource
  products: Product[]
}

const Desktop: FC<Props> = ({ resourcePage, products }) => {
  const { title } = resourcePage
  return (
    <Container className={s.container}>
      <Row className={s.detailContainer}>
        <Col md={8} className={s.mainContainer}>
          <Row className={s.titleContainer}>
            <Col>
              <h1>{title}</h1>
            </Col>
          </Row>
          <ImageDescriptionContainer resourcePage={resourcePage} />
          <SourcesContainer resourcePage={resourcePage} />
        </Col>
        <Col md={4} className={s.rightContainer}>
          <AuthorsInfoContainer resourcePage={resourcePage} />
          {products.length > 0 && (
            <RelatedProductsContainer products={products} />
          )}
          <RelatedResourcesContainer resourcePage={resourcePage} />
        </Col>
      </Row>
    </Container>
  )
}

const Mobile: FC<Props> = ({ resourcePage, products }) => {
  const { title } = resourcePage
  return (
    <Container>
      <Row className={s.titleContainer}>
        <Col>
          <h1>{title}</h1>
        </Col>
      </Row>
      <AuthorsInfoContainer resourcePage={resourcePage} />
      <ImageDescriptionContainer resourcePage={resourcePage} />
      <SourcesContainer resourcePage={resourcePage} />
      {products.length > 0 && <RelatedProductsContainer products={products} />}
      <RelatedResourcesContainer resourcePage={resourcePage} />
    </Container>
  )
}

export default {
  Desktop,
  Mobile,
}
