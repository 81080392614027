import { Col, Row } from "react-bootstrap"
import React, { FC } from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Resource } from "@utils/globalTypes"
import TextWithArrow from "@components/common/TextWithArrow"
import * as s from "./RelatedResource.module.scss"

type Props = {
  resource: Resource
}
const RelatedResource: FC<Props> = ({ resource }) => {
  const {
    title,
    image,
    _rawContent,
    slug: { current },
    category,
  } = resource

  const desc =
    (_rawContent &&
      _rawContent?.find((content) =>
        content?.children?.find((child) => child._type === "span")
      ).children) ||
    []

  const path = `/knowledge/${
    category?.slug?.current || "uncategorized"
  }/${current}/`

  return (
    <Row>
      <Col className={s.imgContainer} xs={5}>
        <GatsbyImage alt="" image={image.asset.gatsbyImageData} />
      </Col>
      <Col xs={7} className={s.titleContainer}>
        <div className={s.title}>{title}</div>
        {desc.length > 0 && (
          <div className={s.description}>
            {desc[0].text.substring(0, 40)}...
          </div>
        )}
        <Link className={`no-underline ${s.link}`} to={path}>
          <TextWithArrow textCustomClass={s.textArrow} text="Learn More" />
        </Link>
      </Col>
    </Row>
  )
}

export default RelatedResource
