import React, { FC } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Card } from "react-bootstrap"
import { ImageSharp } from "@utils/globalTypes"
import TextWithArrow from "@components/common/TextWithArrow"
import * as s from "./ProductCard.module.scss"

interface ProductCardProps {
  title: string
  description: string
  handle: string
  images: ImageSharp[]
  descriptionCharacters?: number
}
const ProductCard: FC<ProductCardProps> = (props) => {
  const {
    images,
    title,
    description,
    handle,
    descriptionCharacters = 100,
  } = props

  const truncatedDescription = `${description.substring(
    0,
    descriptionCharacters
  )}...`

  return (
    <Card
      as={Link}
      to={`/products/${handle.replace("_", "/")}`}
      className={s.card}
    >
      <Card.Img className={s.imageContainer} as="div" variant="top">
        {images && images[0] && images[0].localFile && (
          <GatsbyImage
            style={{ maxHeight: "100%" }}
            imgStyle={{ objectFit: "contain" }}
            alt="confirm bio"
            image={images[0].localFile.childImageSharp.gatsbyImageData}
          />
        )}
      </Card.Img>
      <Card.Body className={s.bodyContainer}>
        <Card.Title className={s.title}>{title}</Card.Title>
        <Card.Text className={s.description}>{truncatedDescription}</Card.Text>
        <TextWithArrow
          style={{
            height: "unset",
            fontWeight: "bold",
            color: "#226355",
            bottom: "1rem",
          }}
          text="View Product"
        />
      </Card.Body>
    </Card>
  )
}

export default ProductCard
