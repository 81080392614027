import * as React from "react"

import { Container, Row } from "react-bootstrap"

import { BaseBlockContent } from "@utils/richText"
import { GatsbyImage } from "gatsby-plugin-image"
import { Resource } from "@utils/globalTypes"
import * as s from "./ImageDescriptionContainer.module.scss"

type Props = {
  resourcePage: Resource
}
const ImageDescriptionContainer: React.FC<Props> = ({ resourcePage }) => {
  const { image, _rawContent } = resourcePage
  return (
    <Container>
      <Row className={s.imgContainer}>
        <GatsbyImage
          alt=""
          className={s.img}
          image={image?.asset?.gatsbyImageData}
        />
      </Row>
      <Row className={s.descContainer}>
        <BaseBlockContent className={s.description} blocks={_rawContent} />
      </Row>
    </Container>
  )
}

export default ImageDescriptionContainer
