import React, { FC } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import * as s from "./TextWithArrow.module.scss"

type Props = {
  text: string
  style?: any
  textCustomClass?: any
}

const TextWithArrow: FC<Props> = ({
  text,
  style = {},
  textCustomClass = s.text,
}) => {
  return (
    <div style={style} className={s.container}>
      <div className={textCustomClass}>{text}</div>
      <FontAwesomeIcon icon={faArrowRight} size="1x" />
    </div>
  )
}

export default TextWithArrow
