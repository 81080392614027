import { Container, Row } from "react-bootstrap"
import React, { FC } from "react"

import RelatedResource from "@components/resources/RelatedResource"
import { Resource } from "@utils/globalTypes"
import * as s from "./RelatedResourcesContainer.module.scss"

type Props = {
  resourcePage: Resource
}

const RelatedResourcesContainer: FC<Props> = ({ resourcePage }) => {
  const { relatedResources } = resourcePage

  return (
    <Container className={s.container}>
      <Row className={s.titleContainer}>
        <h2 className={s.title}>Related Resources</h2>
      </Row>
      {relatedResources.map((res, index) => (
        <RelatedResource key={index} resource={res} />
      ))}
    </Container>
  )
}

export default RelatedResourcesContainer
