import { Col, Container, Row } from "react-bootstrap"
import React, { FC } from "react"

import { Product } from "@utils/globalTypes"
import ProductCard from "@components/collections/ProductCard"
import * as s from "./RelatedProductsContainer.module.scss"

type Props = {
  products: Product[]
}
const RelatedProductsContainer: FC<Props> = ({ products }) => {
  return (
    <Container className={s.container}>
      <Row className="justify-content-center">
        <Col xs={12}>
          <h2 className={s.title}>Related Products</h2>
        </Col>
        {products.map((product, index) => (
          <Col
            key={index}
            xs={12}
            sm={6}
            md={6}
            lg={12}
            className={s.productContainer}
          >
            <ProductCard
              handle={product.handle}
              images={product.images}
              description={product.description}
              title={product.title}
              descriptionCharacters={150}
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default RelatedProductsContainer
